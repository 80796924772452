import React from 'react';
import firebase from 'firebase/compat/app';
import { UserMenu } from '../UserMenu/UserMenu';
import { Socket } from 'socket.io-client';
import { Comment, Icon } from 'semantic-ui-react';
import styles from './Chat.module.css';
import classes from './Chat.module.css';
import { getColorForStringHex, getDefaultPicture } from '../../utils';

interface IParticipants {
  nameMap: StringDict;
  owner: string | undefined;
  roomId: string;
  user: firebase.User | undefined;
  pictureMap: StringDict;
  uniqueUids: string[];
  socket: Socket;
}

const Participants: React.FC<IParticipants> = ({
  nameMap,
  pictureMap,
  owner,
  roomId,
  socket,
  user,
  uniqueUids,
}) => {
  return (
    <div style={{ color: 'white', maxHeight: '600px', overflow: 'auto' }}>
      {uniqueUids.map((uid, uidIndex) => {
        return (
          <div className={styles.mainListDiv} key={uidIndex}>
            <div className={styles.listInnerDiv}>
              <div className={styles.profileShield}>
                <Comment.Avatar
                  className={classes.subscriber}
                  src={
                    pictureMap[uid] ||
                    getDefaultPicture(nameMap[uid], getColorForStringHex(uid))
                  }
                />
                <div className={styles.shieldDiv}>
                  <svg
                    fill="#27ae60"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0z" />
                  </svg>
                </div>
              </div>

              <span>{nameMap[uid]}</span>
            </div>
            <UserMenu
              displayName={nameMap[uid] || uid}
              user={user}
              timestamp={new Date().getMilliseconds().toString()}
              socket={socket}
              userToManage={user?.uid || uid}
              isChatMessage={false}
              disabled={!Boolean(owner && owner === user?.uid)}
              trigger={
                <Comment.Author as="a" className={styles.light}>
                  {Boolean(false) && 'System'}
                  <button className={styles.moreBtn}>
                    <Icon name="ellipsis vertical" />
                  </button>
                </Comment.Author>
              }
              roomId={roomId}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Participants;
