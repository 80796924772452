import React from 'react';
import { Modal, Button, Table, Icon } from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import { SubscribeButton } from '../SubscribeButton/SubscribeButton';

export class ScreenShareModal extends React.Component<{
  closeModal: () => void;
  startScreenShare: (useMediaSoup: boolean) => void;
  isSubscriber: boolean;
  user: firebase.User | undefined;
  beta?: boolean;
}> {
  render() {
    const { closeModal } = this.props;
    const subscribeButton = !this.props.isSubscriber ? (
      <SubscribeButton user={this.props.user} />
    ) : null;
    return (
      <Modal
        open={true}
        onClose={closeModal}
        size={'tiny'}
        className="mainmodel"
      >
        <Modal.Header className="headercolor borderset">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span className="spandesc">Share Your Screen</span>
            <Icon
              name="close"
              style={{ color: '#27AE60' }}
              onClick={() => {
                this.props.closeModal();
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Content className="headercolor">
          <ul>
            <li className="popupdes">
              To share audio, the "Share audio" checkbox needs to be checked in
              the screen selection dialog.
            </li>
            <li className="popupdes">
              Audio sharing is only supported if sharing your entire screen or a
              browser tab, not an application.
            </li>
          </ul>
        </Modal.Content>
        <Modal.Actions className="headercolor">
          <Button
            style={{ width: '100%' }}
            className="buttonfooter"
            onClick={() => {
              this.props.startScreenShare(false);
              this.props.closeModal();
            }}
          >
            Start Screen Sharing
          </Button>
        </Modal.Actions>

        {/* <Modal.Content image>
          <Modal.Description>
            <div>You're about to share your screen.</div>
            <ul>
              <li>To share audio, the "Share audio" checkbox needs to be checked in the screen selection dialog.</li>
              <li>
                Audio sharing is only supported if sharing your entire screen or a browser tab, not an application.
              </li>
            </ul>
            <Table definition unstackable striped celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>WatchParty Free</Table.HeaderCell>
                  <Table.HeaderCell>WatchParty Plus</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>Method</Table.Cell>
                  <Table.Cell>
                    Stream your video to each viewer individually.
                  </Table.Cell>
                  <Table.Cell>
                    Our relay server streams to viewers for you.
                    <br />
                    Higher quality and lower bandwidth usage.
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Latency</Table.Cell>
                  <Table.Cell>{`< 1s`}</Table.Cell>
                  <Table.Cell>{`< 1s`}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Recommended Max Viewers</Table.Cell>
                  <Table.Cell>5</Table.Cell>
                  <Table.Cell>20</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Recommended Upload Speed</Table.Cell>
                  <Table.Cell>5 Mbps per viewer</Table.Cell>
                  <Table.Cell>5 Mbps</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <Button
                      onClick={() => {
                        this.props.startScreenShare(false);
                        this.props.closeModal();
                      }}
                    >
                      Start Screenshare
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      color="orange"
                      onClick={() => {
                        this.props.startScreenShare(true);
                        this.props.closeModal();
                      }}
                    >
                      Start Screenshare w/Relay
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content> */}
      </Modal>
    );
  }
}
