import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const AUTH_COOKIE_NAME = 'udt';

export const getJwtToken = () => {
  return Cookies.get(AUTH_COOKIE_NAME);
};

export const removeCookie = (cookieName: string = AUTH_COOKIE_NAME) => {
  Cookies.remove(cookieName);
};

export const isTokenExpired = () => {
  const token = getJwtToken();
  if (!token) {
    return true;
  }

  // @NOTE: As per conversation with chirag, we do not set expiry time of the JWT token
  // only way to check that if token cookie is there, we can consider this as valid session.

  try {
    const decodedToken: { exp: number } = jwtDecode(token);
    console.log({ decodedToken });
    // TODO: validate token expiry time
    // const currentTime = Date.now() / 1000;
    // return decodedToken.exp * 1000 < currentTime;
    // return true;
  } catch (error) {
    console.error('Error while validating session', error);
    // return false;
  }

  return false;
};
