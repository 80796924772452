import { createContext, useState, useEffect, useContext, useRef } from 'react';
import { checkRoomAccess } from '../api/user';

interface Props {
  children: any;
  roomId: string;
  user?: User;
}

export interface RoomContextType {
  isRoomPrivate: boolean | null;
  privateRoomMessage: string;
}

export const RoomContext = createContext<RoomContextType>({
  isRoomPrivate: null,
  privateRoomMessage: '',
});

export function useRoom() {
  return useContext(RoomContext);
}

const RoomContextProvider = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRoomPrivate, setIsRoomPrivate] = useState<boolean | null>(null);
  const [privateRoomMessage, setPrivateRoomMessage] = useState<string>('');

  useEffect(() => {
    if (props.user?.uid) {
      checkRoomAccess(props.roomId, props.user?.uid)
        .then(({ data }) => {
          let message = '';
          if (!data.access) {
            message = data.message;
          }

          setIsRoomPrivate(!data.access);
          setPrivateRoomMessage(message);
        })
        .catch((_error) => {
          console.error('Error while checking room access');
          setIsRoomPrivate(true);
          setPrivateRoomMessage('Error while checking room access');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <h3 style={{ color: 'white' }}>Loading....</h3>
      </div>
    );
  }

  return (
    <RoomContext.Provider value={{ isRoomPrivate, privateRoomMessage }}>
      {props.children}
    </RoomContext.Provider>
  );
};

export default RoomContextProvider;
