import watchPartyApi from '../services/watchPartyApiService';
import { getJwtToken } from '../utils/auth';

export const createNewRoom = (payload: { uid: string; video: string }) => {
  if (!payload.video) {
    payload.video = '';
  }
  console.log('calling create room api', getJwtToken());
  return watchPartyApi.post('/createRoom', payload);
};
