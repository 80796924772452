import dmdbApi from '../services/dmdbApiService';

export const getUserDetails = () => {
  return dmdbApi.get<UserDetailResponse>(
    '/api/v1/watch_party/users/user_details'
  );
};

export const isUserFanOrFriend = (userId: string) => {
  return dmdbApi.post(`/api/v1/watch_party/users/user_friend_fan`, {
    user: userId,
  });
};

export const kickUser = (roomId: string, userId: string) => {
  const room = roomId.replaceAll('/', '');
  return dmdbApi.post(`/api/v1/watch_party/watch_parties/${room}/kick_out`, {
    user: userId,
  });
};

export const checkRoomAccess = (roomId: string, userId: string) => {
  const room = roomId.replaceAll('/', '');
  return dmdbApi.post<{
    access: boolean;
    message: string;
  }>(`/api/v1/watch_party/watch_parties/${room}/check_access`, {
    user: userId,
  });
};

export const startParty = (roomId: string) => {
  return dmdbApi.post(
    `/api/v1/watch_party/watch_parties/${roomId.replaceAll('/', '')}/started`
  );
};

export const endParty = (roomId: string) => {
  return dmdbApi.post(
    `/api/v1/watch_party/watch_parties/${roomId.replaceAll('/', '')}/ended`
  );
};

export const changeHost = (user: string, roomId: string) => {
  return dmdbApi.post(
    `/api/v1/watch_party/watch_parties${roomId}/assign_co_host`,
    { user }
  );
};
