import axios from 'axios';
import { getJwtToken } from '../utils/auth';

const watchPartyApi = axios.create({
  baseURL: process.env.REACT_APP_WATCH_PARTY_API_DOMAIN,
  // add any other default headers here
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

// add a request interceptor to set authentication headers
watchPartyApi.interceptors.request.use(function (config) {
  const token = getJwtToken();
  console.log('cookie', { watchPartyApi: token });
  if (token) {
    config.headers['token'] = token;
  }
  return config;
});

export default watchPartyApi;
