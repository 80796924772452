import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Modal,
  ModalActions,
  ModalContent,
  ModalDescription,
} from 'semantic-ui-react';
import { changeHost, endParty } from '../../api/user';
import { Socket } from 'socket.io-client';

type IHostLeaveDialog = {
  open: boolean;
  setOpen: (value: boolean) => void;
  roomId: string;
  socket: Socket;
  participants: User[];
  nameMap: StringDict;
  pictureMap: StringDict;
  setMedia: (e: any, data: DropdownProps) => void;
  redirectToWatchPartyDomain: () => void;
};

const HostLeaveDialog: React.FC<IHostLeaveDialog> = ({
  open,
  setOpen,
  roomId,
  participants,
  socket,
  nameMap,
  pictureMap,
  setMedia,
  redirectToWatchPartyDomain,
}) => {
  const [options, setOptions] = useState<
    { key: string; text: string; value: string; image: string }[]
  >([]);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [showMakeHost, setShowMakeHost] = useState<boolean>(false);

  const handleWatchPartyEnd = useCallback(
    async (roomId: string) => {
      try {
        await endParty(roomId);
        setMedia(null, { value: '' });
        socket.emit('CMD:endParty');
        redirectToWatchPartyDomain();
      } catch (e) {
        console.log(e);
      }
    },
    [socket, setMedia, redirectToWatchPartyDomain]
  );

  const handleChangeHost = useCallback(
    async (roomId: string, userId: string) => {
      try {
        const res = await changeHost(userId, roomId);
        socket.emit('CMD:setRoomCoHost', {
          uid: userId,
          token: res.data.token,
        });
        // socket.emit('CMD:setRoomOwner', { uid: userId, token: res.data.token });
        setOpen(false);
        redirectToWatchPartyDomain();
      } catch (e) {
        console.log(e);
      }
    },
    [setOpen, redirectToWatchPartyDomain, socket]
  );

  useEffect(() => {
    const options: {
      key: string;
      text: string;
      value: string;
      image: string;
    }[] = [];
    if (
      participants.length &&
      Object.keys(nameMap).length > 0 &&
      Object.keys(pictureMap).length > 0
    ) {
      for (let i = 0; i < participants.length; i++) {
        options.push({
          key: participants[i].uid ?? '',
          image: pictureMap[participants[i].id as string],
          text: nameMap[participants[i].id],
          value: participants[i].uid ?? '',
        });
      }
      setOptions(options);
    }
  }, [participants, nameMap, pictureMap]);

  return (
    <Modal
      onClose={() => {
        setShowMakeHost(false);
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      style={{ border: '1px solid green' }}
      size="mini"
      className="gradient-modal"
    >
      <ModalContent content style={{ backgroundColor: 'transparent' }}>
        <ModalDescription>
          {!showMakeHost ? (
            <>
              <h5 style={{ color: '#fff', fontSize: 18 }}>Assign co host?</h5>
              <p style={{ fontSize: 14 }}>Would you like to assign co host?</p>
            </>
          ) : (
            <Dropdown
              placeholder="Select Friend"
              fluid
              selection
              options={options}
              value={selectedUser}
              onChange={(e, data) => {
                setSelectedUser(data.value as unknown as string);
              }}
              style={{ backgroundColor: '#282b30', color: '#fff' }}
              className="gradient-outline-btn"
            />
          )}
        </ModalDescription>
      </ModalContent>
      <ModalActions style={{ backgroundColor: 'transparent' }}>
        {!showMakeHost ? (
          <>
            <Button
              className="gradient-outline-btn"
              onClick={() => handleWatchPartyEnd(roomId)}
            >
              <span>No</span>
            </Button>
            <Button
              content="Select host"
              onClick={() => setShowMakeHost(true)}
              positive
              className="gradient-btn"
            />
          </>
        ) : (
          <>
            <Button
              className="gradient-outline-btn"
              onClick={() => setShowMakeHost(false)}
            >
              <span>Back</span>
            </Button>
            <Button
              disabled={!selectedUser}
              content="Make host"
              onClick={() => handleChangeHost(roomId, selectedUser)}
              positive
              className="gradient-btn"
            />
          </>
        )}
      </ModalActions>
    </Modal>
  );
};

export default HostLeaveDialog;
