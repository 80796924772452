import { createContext, useState, useEffect, useContext, useRef } from 'react';
import { isTokenExpired, removeCookie } from '../utils/auth';
import { getUserDetails } from '../api/user';

interface Props {
  children: any;
}

interface AuthContext {
  user: any | null;
  logout: () => void;
}

export const AuthContext = createContext<AuthContext>({
  user: null,
  logout: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

const AuthContextProvider = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const composeUserObject = (user: UserDetailResponse): User => {
    return {
      id: user.id.toString(),
      uid: user.number,
      displayName: `${user.first_name} ${user.last_name}`,
      photoUrl: user.profile_url,
    };
  };

  useEffect(() => {
    if (!isTokenExpired()) {
      getUserDetails()
        .then(({ data }) => {
          setUser(composeUserObject(data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const logout = () => {
    removeCookie();
    setUser(null);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <h3 style={{ color: 'white' }}>Loading....</h3>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
