import axios from 'axios';
import { getJwtToken } from '../utils/auth';

const dmdbApi = axios.create({
  baseURL: process.env.REACT_APP_DMDB_DOMAIN,
  // add any other default headers here
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

// add a request interceptor to set authentication headers
dmdbApi.interceptors.request.use(function (config) {
  const token = getJwtToken();
  // console.log('cookie', { dmdbApi: token });
  if (token) {
    config.headers['token'] = token;
  }
  return config;
});

export default dmdbApi;
