import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { useAuth } from '../../contexts/AuthContextProvider';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  redirectTo?: string;
  [key: string]: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  redirectTo,
  ...rest
}) => {
  const { user } = useAuth();

  const redirectToLogin = () => {
    const currentUrl = window.location.href;
    const url = `${process.env.REACT_APP_DMDB_DOMAIN}/users/sign_in?redirectUrl=${currentUrl}`;
    window.location.href = url;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (redirectTo) {
          window.location.href = redirectTo;
          return;
        }
        if (user) {
          return <Component {...props} />;
        } else {
          redirectToLogin();
        }
      }}
    />
  );
};

export default PrivateRoute;
