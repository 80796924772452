import React, { useState } from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { Socket } from 'socket.io-client';
import firebase from 'firebase/compat/app';
import styles from './UserMenu.module.css';
import { getJwtToken } from '../../utils/auth';
import { kickUser } from '../../api/user';

export const UserMenu = ({
  user,
  socket,
  userToManage,
  trigger,
  displayName,
  position,
  disabled,
  timestamp,
  isChatMessage,
  roomId,
}: {
  user?: firebase.User;
  socket: Socket;
  userToManage: string;
  trigger: any;
  icon?: string;
  displayName?: string;
  position?: any;
  disabled: boolean;
  timestamp?: string;
  isChatMessage?: boolean;
  roomId: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  return (
    <Popup
      className={styles.userMenu}
      trigger={trigger}
      on="click"
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      position={position}
      disabled={disabled}
    >
      <div className={styles.userMenuContent}>
        <button
          onClick={async () => {
            const token = await getJwtToken();
            await kickUser(roomId, userToManage);
            socket.emit('kickUser', {
              userToBeKicked: userToManage,
              uid: user?.uid,
              token,
            });
            setIsOpen(false);
          }}
        >
          Kick
        </button>
        {isChatMessage && (
          <button
            onClick={async () => {
              const token = await user?.getIdToken();
              socket.emit('CMD:deleteChatMessages', {
                author: userToManage,
                timestamp: timestamp,
                uid: user?.uid,
                token,
              });
              setIsOpen(false);
            }}
          >
            Delete Message
          </button>
        )}
        <button
          onClick={async () => {
            const token = await user?.getIdToken();
            socket.emit('CMD:deleteChatMessages', {
              author: userToManage,
              uid: user?.uid,
              token,
            });
            setIsOpen(false);
          }}
        >
          Delete User's Messages
        </button>
      </div>
    </Popup>
  );
};
